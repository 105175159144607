import { FC } from "react";

export const AcknowledgePrice: FC<{
  acknowledgedPrice: boolean,
  setAcknowledgedPrice: (c: boolean) => void
}> = ({ acknowledgedPrice, setAcknowledgedPrice }) => {
  return (
    <div className="row mt-3">
      <div className="col"></div>
      <div className="col-auto">
        <div className="form-check" >
          <label className="form-check-label">
            <input type="checkbox" className="form-check-input" onClick={() => setAcknowledgedPrice(!acknowledgedPrice)} />
            I understand that uploading a new Photo will cause a $10 ID Card reissue fee
          </label>
        </div>
      </div>
      <div className="col"></div>
    </div>
  )
}