import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { User } from "oidc-client-ts";
import { AppRoutes } from "./AppRoutes";
import { useTabFocus } from "./useTabFocus";

function App() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [authenticateNextLoad, setAuthenticateNextLoad] = useState(false);

  const setCookie = (user: User) => {
    const token = user?.access_token;
    const expiresDate = new Date(0);
    expiresDate.setUTCSeconds(user.expires_at ?? 0);
    document.cookie = `jwt=${token}; expires=${expiresDate.toUTCString()}; samesite=Strict; path=/api;`;
  };
  if (auth.user) setCookie(auth.user);

  const reauthenticateIfSessionExpiredInBackground = () => {
    if (authenticateNextLoad) {
      console.log("authenticateing after expired background session");
      setAuthenticateNextLoad(false);
      try {
        auth.signinRedirect().catch(e => {
          console.log("error on sign in redirect after background session expired", e)
          auth.signinPopup().catch((e) => {
            console.log(
              "Signin popup on returned error after background session expired",
              e
            );
            setAuthenticateNextLoad(true);
          });
        });
      } catch (e) {
        console.log("other error catch after background session expired", e);
      }
    }
  };
  useTabFocus({ onFocus: reauthenticateIfSessionExpiredInBackground });

  useEffect(() => {
    if (
      !auth.activeNavigator &&
      !auth.isLoading &&
      !auth.error &&
      !auth.isAuthenticated
    ) {
      const currentPath = window.location.pathname + window.location.search;
      localStorage.setItem("redirect_landing", currentPath);
      auth.signinRedirect();
    }
  });

  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      console.log("running silent signin");
      auth.signinSilent().then((user) => {
        console.log("after silent sign in");
        if (user) {
          console.log("setting cookie");
          setCookie(user);
        } else {
          console.log("no user given");
        }
      });
    });
  }, [auth.events, auth.signinSilent, auth]);

  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addSilentRenewError(() => {
      console.log("silent renew error, using signin popup");
      console.log("visibility state", document.visibilityState);
      try {
        auth.signinPopup().catch((e) => {
          console.log("Signin popup error", e);
          setAuthenticateNextLoad(true);
        });
      } catch (e) {
        console.log("other error catch", e);
      }
    });
  }, [auth.events, auth]);
  // guide to windown visibility https://blog.sethcorker.com/harnessing-the-page-visibility-api-with-react/
  // const onVisibilityChange = () => {
  //   if (document.visibilityState === "visible") {
  //     console.log("Tab reopened, refetch the data!");
  //   }
  // };
  // useLayoutEffect(() => {
  //   document.addEventListener("visibilitychange", onVisibilityChange);

  //   return () =>
  //     document.removeEventListener("visibilitychange", onVisibilityChange);
  // }, []);

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }
  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }
  if (auth.isAuthenticated) {
    const landing = localStorage.getItem("redirect_landing");
    if (landing) {
      localStorage.removeItem("redirect_landing");
      if (landing.includes("login/landing")) navigate(landing);
      else navigate("/");
      return <></>;
    }
    return <AppRoutes />;
  }
  return <button onClick={() => void auth.signinRedirect()}>Log in</button>;
}

export default App;
