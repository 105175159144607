import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import toast from "react-hot-toast";
import { camel_to_snake, snakeToCamel } from "./apiMapper";

export const photoService = {
  async uploadPhoto(
    campus: string,
    profilePhoto: string,
    idPhoto: string,
    isMedStudent: boolean,
    acknowledgedFee: boolean,
    authHeader: AxiosRequestHeaders
  ) {
    const url = "/api/student/idphotoupload";
    const body = camel_to_snake({
      campus,
      profilePhoto,
      idPhoto,
      isMedStudent,
      acknowledgedFee,
    });
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axios.post(url, body, options);
    toast.success("Uploaded Photo!");
    return snakeToCamel(response.data);
  },
  async getStatus(authHeader: AxiosRequestHeaders) {
    const url = "/api/student/photostatus";
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axios.get(url, options);
    return snakeToCamel(response.data);
  },
  async checkFeeRequired(authHeader: AxiosRequestHeaders) {
    const url = "/api/student/feerequired";
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axios.get(url, options);
    return snakeToCamel(response.data);
  },
  // async getPhoto(authHeader: AxiosRequestHeaders) {
  //   const url = "/api/student/currentprofilephoto";
  //   const options: AxiosRequestConfig = { headers: authHeader };
  //   const response = await axios.get(url, options);
  //   return snakeToCamel(response.data);
  // },
};
