import { Spinner } from "../../../components/ui/Spinner";
import { useGetAwaitingApprovalQuery } from "./approvalHooks";
import { ApproveItem } from "./ApproveItem";

export const Approve = () => {
  const inProgressQuery = useGetAwaitingApprovalQuery();
  if (inProgressQuery.isFetching) return <Spinner />;
  if (inProgressQuery.isError) return <h3 className="text-center">Error getting In Progress requests</h3>
  if (!inProgressQuery.data) return <h3 className="text-center">No data found</h3>
  if (inProgressQuery.data.length === 0)
    return <h3 className="text-center">No Requests Needing Approval</h3>;

  return (
    <div>
      {inProgressQuery.data.map((p) => (
        <ApproveItem photoInfo={p} key={p.badgerid} />
      ))}
    </div>
  );
};
