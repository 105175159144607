import { useMutation, useQuery } from "react-query";
import { adminService } from "./adminService";
import { getQueryClient } from "../../services/queryClient";
import { useAccessTokenHeader } from "../../hooks/userHooks";

const queryClient = getQueryClient();

export const adminKeys = {
  approvedKey: ["approved"] as const,
  approvedMedKey: ["approvedmed"] as const,
  deniedKey: ["denied"] as const,
  completedKey: ["completed"] as const,
  profilePhotosKey: (badgerid: string) => ["photos", badgerid] as const,
  identityPhotosKey: (badgerid: string) => ["identity", badgerid] as const,
  blackboardPhotoKey: (badgerid: string) => ["blackboard", badgerid] as const,
};

export const useGetApprovedQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery(
    adminKeys.approvedKey,
    async () => {
      return await adminService.getApproved(authHeader);
    },
    { staleTime: 300000 }
  );
};
export const useGetApprovedMedQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery(
    adminKeys.approvedMedKey,
    async () => {
      return await adminService.getApprovedMed(authHeader);
    },
    { staleTime: 300000 }
  );
};

export const useGetDeniedQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery(adminKeys.deniedKey, async () => {
    return await adminService.getDenied(authHeader);
  });
};

export const useGetCompletedQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery(adminKeys.completedKey, async () => {
    return await adminService.getCompleted(authHeader);
  });
};

export const useGetPhotoQuery = (badgerid: string) => {
  const authHeader = useAccessTokenHeader();
  return useQuery(adminKeys.profilePhotosKey(badgerid), async () => {
    return await adminService.getProfilePhoto(badgerid, authHeader);
  });
};

export const useGetIdentityPhotoQuery = (badgerid: string) => {
  const authHeader = useAccessTokenHeader();
  return useQuery(adminKeys.identityPhotosKey(badgerid), async () => {
    return await adminService.getIdentityPhoto(badgerid, authHeader);
  });
};

export const useGetBlackboardPhotoQuery = (badgerid: string) => {
  const authHeader = useAccessTokenHeader();
  return useQuery(adminKeys.blackboardPhotoKey(badgerid), async () => {
    return await adminService.getBlackboardPhotos(badgerid, authHeader);
  });
};

export const useMarkCompleteMutation = (badgerid: string) => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async () => {
      return await adminService.markComplete(badgerid, authHeader);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(adminKeys.approvedKey);
        queryClient.invalidateQueries(adminKeys.approvedMedKey);
        queryClient.invalidateQueries(adminKeys.completedKey);
      },
    }
  );
};

export const useCheckForPrintedPhotosMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async () => {
      return await adminService.checkForPrintedPhotos(authHeader);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(adminKeys.approvedKey);
        queryClient.invalidateQueries(adminKeys.approvedMedKey);
        queryClient.invalidateQueries(adminKeys.completedKey);
      },
    }
  );
};
