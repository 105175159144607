import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { PhotoUploadButton } from "../../components/forms/PhotoUploadButton"
import { useCheckFeeRequiredQuery, useUploadPhotoMutation } from "../../hooks/photoHooks"
import { AcknowledgePrice } from "./AcknowledgePrice"
import { CampusSelect } from "./CampusSelect"


export const Upload = () => {
  const [isMedStudent, setIsMedStudent] = useState(false)
  const [acknowledgedPrice, setAcknowledgedPrice] = useState(false)
  const [governmentPhotoUrl, setGovernmentPhotoUrl] = useState("")
  const [campus, setCampus] = useState("")
  const [croppedPhotoUrl, setCroppedPhotoUrl] = useState("")
  const navigate = useNavigate();

  const uploadPhotoMutation = useUploadPhotoMutation();
  const feeRequiredQuery = useCheckFeeRequiredQuery();
  const setIsHealthProfession = () => {
    setIsMedStudent(!isMedStudent)
    setAcknowledgedPrice(false)
  }

  const submitHandler = () => {
    uploadPhotoMutation.mutateAsync({
      campus,
      profilePhotoUrl: croppedPhotoUrl,
      idPhotoUrl: governmentPhotoUrl,
      isMedStudent,
      acknowledgedFee: acknowledgedPrice
    }).then(() => navigate({
      pathname: "/",
    }))
  }

  const canSelectCampus = croppedPhotoUrl && governmentPhotoUrl
  const canAcknowledgePrice = !isMedStudent && feeRequiredQuery.data

  if (feeRequiredQuery.isError) throw Error("Cannot proceed without fee info")

  return (
    <div className="text-center mt-2">
      <h1>Snow College Id Photo Upload</h1>
      <hr />
      <div className="row">
        <div className="col"></div>
        <div className="col-auto text-start">
          <div className="fs-5 text-decoration-underline">Your photo should meet the following requirements:</div>
          <ul style={{ listStylePosition: "inside" }}>
            <li>Photo must be recent and in color (a selfie is preferred)</li>
            <li>Use a plain, light-colored background (preferably white or tan)</li>
            <li>Image must be clear and devoid of filters</li>
            <li>No hats, hand signs, sunglasses, or masks</li>
            <li>Face the camera directly with full face in view</li>
          </ul>
        </div>
        <div className="col"></div>
      </div>
      <div className="form-check" >
        <label className="form-check-label">
          <input type="checkbox" className="form-check-input" onClick={setIsHealthProfession} />
          I am submitting this photo for my Health Professions, Cosmo or Nail Tech class and do not want a new ID card
        </label>
      </div>
      <hr />
      <PhotoUploadButton
        buttonLabel="Upload Id Photo"
        shouldCrop={true}
        setCroppedPhotoUrl={setCroppedPhotoUrl}
      />
      {croppedPhotoUrl && (
        <div className="mt-2">
          <img src={croppedPhotoUrl} alt="Uploaded" height={200} />
        </div>
      )}
      <div className="fs-5 mt-3">Upload a picture of your driver's license (or other government id) for verification</div>
      <PhotoUploadButton buttonLabel="Upload Government ID Photo" setCroppedPhotoUrl={setGovernmentPhotoUrl} />
      {governmentPhotoUrl && (
        <div className="my-2">
          <img src={governmentPhotoUrl.toString()} alt="Government ID" height={200} />
        </div>
      )}
      {canAcknowledgePrice &&
        <AcknowledgePrice acknowledgedPrice={acknowledgedPrice} setAcknowledgedPrice={setAcknowledgedPrice} />}
      {canSelectCampus &&
        <CampusSelect setCampus={setCampus} />}
      <div className="row my-4">
        <button className="btn btn-bold"
          disabled={campus.length === 0 || uploadPhotoMutation.isLoading}
          onClick={submitHandler}>
          Submit Selected Photos
        </button>
      </div>
    </div>
  )
}