import { useMutation, useQuery } from "react-query";
import { photoService } from "../services/photoService";
import { useAccessTokenHeader } from "./userHooks";
import { adminApprovalKeys } from "../pages/admin/approve/approvalHooks";
import { getQueryClient } from "../services/queryClient";

const queryClient = getQueryClient();

const statusKey = "statusKey";
const feeRequired = "feeRequired";

export const useUploadPhotoMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      campus,
      profilePhotoUrl,
      idPhotoUrl,
      isMedStudent,
      acknowledgedFee,
    }: {
      campus: string;
      profilePhotoUrl: string;
      idPhotoUrl: string;
      isMedStudent: boolean;
      acknowledgedFee: boolean;
    }) => {
      return await photoService.uploadPhoto(
        campus,
        profilePhotoUrl,
        idPhotoUrl,
        isMedStudent,
        acknowledgedFee,
        authHeader
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(adminApprovalKeys.awaitingApproval);
      },
    }
  );
};

export const useGetStatusQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery([statusKey], async () => {
    return await photoService.getStatus(authHeader);
  });
};

export const useCheckFeeRequiredQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery([feeRequired], async () => {
    return await photoService.checkFeeRequired(authHeader);
  });
};

// export const useGetPhoto = () => {
//   const authHeader = useAccessTokenHeader();
//   return useQuery([photoKey, authHeader.Authorization], async () => {
//     return await photoService.getPhoto(authHeader)
//   })
// }
