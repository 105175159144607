import { Spinner } from "../../components/ui/Spinner";
import { useGetDeniedQuery } from "./adminHooks";

export const Denied = () => {
  const deniedQuery = useGetDeniedQuery();

  if (deniedQuery.isFetching) return <Spinner />;
  if (deniedQuery.isError) return <h3 className="text-center">Error getting denied requests</h3>
  if (!deniedQuery.data) return <h3 className="text-center">No data found</h3>
  if (deniedQuery.data.length === 0)
    return <h3 className="text-center">No Denied Requests</h3>;

  return (
    <div>
      {deniedQuery.data.map((p) => (
        <div className="row" key={p.badgerid}>
          <div className="col text-end">{p.displayName}:</div>
          <div className="col">{p.status}</div>
        </div>
      ))}
    </div>
  );
};
