import {
    AnyAction,
    createSlice,
    PayloadAction,
    ThunkDispatch,
  } from "@reduxjs/toolkit";
  import { AxiosError } from "axios";
  import { StoreDispatch, StoreState } from "./store";
  
  interface AlertState {
    alerts: { message: string; id: string }[];
  }
  
  export const initialState: AlertState = {
    alerts: [],
  };
  function randomId() {
    return (Math.random() + 1).toString(36).substring(7);
  }
  export const alertSlice = createSlice({
    name: "alertSlice",
    initialState,
    reducers: {
      addAlert: (state, action: PayloadAction<string>) => {
        console.log("adding error", action.payload);
        state.alerts.push({
          message: JSON.stringify(action.payload),
          id: randomId(),
        });
      },
      clearAlert: (state, action: PayloadAction<{ id: string }>) => {
        state.alerts = state.alerts.filter((a) => a.id !== action.payload.id);
      },
      apiError: (state, action: PayloadAction<AxiosError>) => {
        if (action.payload.isAxiosError) {
          if (action.payload.response?.status === 422) {
            const location = `location ${action.payload.response?.data.detail[0].loc.join(
              " -> "
            )}`;
            const responseMessage = action.payload.response?.data.detail[0].msg;
            const responseType = action.payload.response?.data.detail[0].type;
  
            const message =
              "Error: " + responseMessage + ", " + responseType + ", " + location;
            state.alerts.push({ message, id: randomId() });
          } else if (action.payload.response?.status === 500) {
            const message =
              action.payload.response.statusText +
              " - " +
              action.payload.request?.path;
            state.alerts.push({
              message,
              id: randomId(),
            });
          } else {
            const message = action.payload.response?.statusText ?? "";
            state.alerts.push({
              message,
              id: randomId(),
            });
          }
        } else {
          const error = action.payload as any;
          console.log("error handler:", action.payload);
          if (error.status === 404) {
            console.log("404");
            const message = action.payload.message
              ? "Not Found: " + error.message + " at " + error.config.url
              : JSON.stringify(action.payload);
            state.alerts.push({
              message,
              id: randomId(),
            });
          } else {
            const message = action.payload.message
              ? action.payload.message + " to " + action.payload.config.url
              : JSON.stringify(action.payload);
            state.alerts.push({
              message,
              id: randomId(),
            });
          }
        }
      },
    },
    extraReducers(_builder) {},
  });
  
  export const { addAlert, clearAlert, apiError } = alertSlice.actions;
  
  export type StoreThunkDispatch = ThunkDispatch<StoreState, void, AnyAction>;
  
  // used to wrap thunks to handle api errors
  export async function alertOnError<T>(
    dispatch: StoreThunkDispatch,
    callback: () => Promise<T>
  ) {
    try {
      return await callback();
    } catch (e: any) {
      if (e.isAxiosError) {
        console.log("axios error");
        dispatch(apiError(JSON.parse(JSON.stringify(e))));
      } else {
        dispatch(addAlert(e.toString()));
      }
      throw e;
    }
  }
  
  // used when api requests are made in components
  export const apiCatch = (dispatch: StoreDispatch, callback?: () => void) => {
    return (e: any) => {
      console.log("error:", e)
      if (e.isAxiosError) {
        console.log("axios error");
        dispatch(apiError(JSON.parse(JSON.stringify(e))));
        if (callback) callback()
      } else {
        console.log('not axios error')
        dispatch(addAlert(e.toString()));
        if (callback) callback()
      }
    };
  };
  