import { FC, useState } from "react";
import { FallbackImage } from "../../../components/ui/FallbackImage";
import { Spinner } from "../../../components/ui/Spinner";
import { IdPhotoInfo } from "../../../models/IdPhotoInfo";
import { useGetIdentityPhotoQuery, useGetPhotoQuery } from "../adminHooks";
import { useMarkApprovedMutation } from "./approvalHooks";
import { DenyReasons } from "./DenyReasons";

export const ApproveItem: FC<{
  photoInfo: IdPhotoInfo;
}> = ({ photoInfo }) => {
  const [chargeFee, setChargeFee] = useState(photoInfo.chargeFee);
  const [denying, setDenying] = useState(false);

  const markApprovedMutation = useMarkApprovedMutation(photoInfo.badgerid);
  const photoQuery = useGetPhotoQuery(photoInfo.badgerid);
  const idPhotoQuery = useGetIdentityPhotoQuery(photoInfo.badgerid);

  const approveHandler = () => markApprovedMutation.mutate(chargeFee);
  if (photoQuery.isLoading || idPhotoQuery.isLoading) return <Spinner />

  const profilePhoto = photoQuery.data;
  const idPhoto = idPhotoQuery.data;


  return (
    <div className="row">
      <div className="col-1"></div>
      <div className="col-4">
        <h3 className="text-center">{photoInfo.displayName}</h3>
        {photoInfo.isEmployee ? <div>Employee</div> : <div>Student</div>}
        <div>{photoInfo.badgerid}</div>
        <div>{photoInfo.campus}</div>
        <div>{new Date(photoInfo.createdTimestamp).toDateString()}</div>
        <div className="d-grid gap-2 col-8 mx-auto mt-3">
          <button
            className="btn btn-outline-bold"
            onClick={approveHandler}
            disabled={markApprovedMutation.isLoading}
          >
            Mark to Approve
          </button>
          {markApprovedMutation.isLoading && <Spinner />}
          <button
            className="btn btn-outline-secondary"
            onClick={() => setDenying(true)}
          >
            Mark to Deny
          </button>
          {denying && (
            <DenyReasons badgerid={photoInfo.badgerid} chargeFee={chargeFee} />
          )}

          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id={"chargeFee" + photoInfo.badgerid}
              checked={chargeFee}
              onChange={() => setChargeFee((c) => !c)}
              disabled={photoInfo.isEmployee}
            />
            <label
              className="form-check-label"
              htmlFor={"chargeFee" + photoInfo.badgerid}
            >
              Charge $10 Fee
            </label>
          </div>
        </div>
      </div>
      <div className="col-3 text-center">
        <h3>New Id</h3>
        <FallbackImage src={"data:image/jpg;base64," + profilePhoto} alt="New Id" error={photoQuery.error} />
      </div>
      <div className="col-3 text-center">
        <h3>Identity Verification</h3>
        <FallbackImage src={"data:image/jpg;base64," + idPhoto} alt="Identity Verification" error={idPhotoQuery.error} />
      </div>
      <hr className="my-4" />
    </div>
  );
};
