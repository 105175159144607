import { ChangeEvent, FC, useRef, useState } from "react";
import { CropModal } from "../CropModal";
import { useModal, ModalButton } from "../ui/CustomModal";
import { createErrorToast } from "../../services/queryClient";

export const PhotoUploadButton: FC<{
  buttonLabel: string,
  shouldCrop?: boolean,
  setCroppedPhotoUrl: (u: string) => void
}> = ({ buttonLabel, shouldCrop = false, setCroppedPhotoUrl }) => {
  const [uploadedPhotoUrl, setUploadedPhotoUrl] = useState("")
  const photoInputRef = useRef<HTMLInputElement>(null)

  const onPhotoUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0]
      if (file.size > 10 * 1024 * 1024) {
        createErrorToast("File is too large. Please upload a photo smaller than 10mb.")
        modalControl.hide();
      }
      else {
        setUploadedPhotoUrl("")
        setCroppedPhotoUrl("")
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = () => {
          const url = reader.result
          if (typeof url === "string") {
            shouldCrop ? setUploadedPhotoUrl(url) : setCroppedPhotoUrl(url)
          }
        }
      }
    }
  }

  const modalHandler = () => {
    photoInputRef?.current?.click();
    modalControl.show();
  }

  const modalControl = useModal(buttonLabel, "lg")

  const ModalButton: ModalButton = () => (
    <div>
      <button
        className="btn btn-bold"
        onClick={modalHandler}
      >
        {buttonLabel}
      </button>
    </div>
  )

  return (
    <div className="mt-1">
      <input
        className="btn btn-primary"
        type="file"
        accept="image/png, image/jpeg"
        ref={photoInputRef}
        hidden
        onChange={onPhotoUpload}
      />
      {!shouldCrop ?
        <button
          className="btn btn-bold"
          onClick={() => photoInputRef?.current?.click()}
        >
          {buttonLabel}
        </button>
        :
        <CropModal
          modalControl={modalControl}
          ModalButton={ModalButton}
          setCroppedPhotoUrl={setCroppedPhotoUrl}
          uploadedPhotoUrl={uploadedPhotoUrl}
          setUploadedPhotoUrl={setUploadedPhotoUrl}
        />
      }
    </div>
  )
}