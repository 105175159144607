import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { IdPhotoInfo } from "../../models/IdPhotoInfo";
import { snakeToCamel } from "../../services/apiMapper";

export const adminService = {
  async getInProgress(authHeader: AxiosRequestHeaders): Promise<IdPhotoInfo[]> {
    const url = "/api/admin/profilephotos/inprogress";
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axios.get(url, options);
    return snakeToCamel(response.data);
  },
  async getApproved(authHeader: AxiosRequestHeaders): Promise<IdPhotoInfo[]> {
    const url = "/api/admin/profilephotos/approved";
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axios.get(url, options);
    return snakeToCamel(response.data);
  },
  async getApprovedMed(
    authHeader: AxiosRequestHeaders
  ): Promise<IdPhotoInfo[]> {
    const url = "/api/admin/profilephotos/approvedmed";
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axios.get(url, options);
    return snakeToCamel(response.data);
  },
  async getDenied(authHeader: AxiosRequestHeaders): Promise<IdPhotoInfo[]> {
    const url = "/api/admin/profilephotos/denied";
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axios.get(url, options);
    return snakeToCamel(response.data);
  },
  async getCompleted(authHeader: AxiosRequestHeaders): Promise<IdPhotoInfo[]> {
    const url = "/api/admin/profilephotos/completed";
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axios.get(url, options);
    return snakeToCamel(response.data);
  },
  async getProfilePhoto(
    badgerid: string,
    authHeader: AxiosRequestHeaders
  ): Promise<string> {
    const url = `/api/admin/profilephotos/${badgerid}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axios.get(url, options);
    return snakeToCamel(response.data);
  },
  async getIdentityPhoto(
    badgerid: string,
    authHeader: AxiosRequestHeaders
  ): Promise<string> {
    const url = `/api/admin/profilephotos/identity/${badgerid}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axios.get(url, options);
    return snakeToCamel(response.data);
  },
  async getBlackboardPhotos(badgerid: string, authHeader: AxiosRequestHeaders) {
    const url = `/api/admin/blackboard/${badgerid}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axios.get(url, options);
    return snakeToCamel(response.data);
  },
  async markApproved(
    badgerid: string,
    chargeFee: boolean,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/admin/profilephotos/approve/${badgerid}?charge_fee=${chargeFee}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axios.post(url, {}, options);
    return snakeToCamel(response.data);
  },
  async markDenied(
    badgerid: string,
    reasons: string[],
    chargeFee: boolean,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/admin/profilephotos/deny/${badgerid}?charge_fee=${chargeFee}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const body = {
      deny_reasons: reasons,
    };
    const response = await axios.post(url, body, options);
    return snakeToCamel(response.data);
  },
  async markComplete(badgerid: string, authHeader: AxiosRequestHeaders) {
    const url = `/api/admin/profilephotos/complete/${badgerid}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axios.get(url, options);
    return snakeToCamel(response.data);
  },
  async checkForPrintedPhotos(authHeader: AxiosRequestHeaders) {
    const url = `/api/admin/check_printed`;
    const options: AxiosRequestConfig = { headers: authHeader };
    await axios.get(url, options);
  },
};
