import { FC } from "react";
import {
  useGetBlackboardPhotoQuery,
  useGetPhotoQuery,
  useMarkCompleteMutation,
} from "../adminHooks";
import { IdPhotoInfo } from "../../../models/IdPhotoInfo";
import { FallbackImage } from "../../../components/ui/FallbackImage";
import { Spinner } from "../../../components/ui/Spinner";

export const PrintItem: FC<{
  photoInfo: IdPhotoInfo;
}> = ({ photoInfo }) => {
  const photoQuery = useGetPhotoQuery(photoInfo.badgerid);
  const profilePhoto = photoQuery.data;

  const blackboardPhotoQuery = useGetBlackboardPhotoQuery(photoInfo.badgerid);
  const blackboardPhoto = blackboardPhotoQuery.data;

  const markCompleteMutation = useMarkCompleteMutation(photoInfo.badgerid);

  const cancelHandler = () => {
    markCompleteMutation.mutate();
  };

  if (photoQuery.isLoading || blackboardPhotoQuery.isLoading) return <Spinner />

  return (
    <>
      <div className="row justify-content-center">
        <div className="col my-auto offset-1">
          <h4>{photoInfo.displayName}</h4>
          <h3>Campus: {photoInfo.campus}</h3>
          <h3>BadgerId: {photoInfo.badgerid}</h3>
        </div>
        <div className="col my-auto">
          <h5>New</h5>
          <FallbackImage src={"data:image/jpg;base64," + profilePhoto} alt="New Id" error={photoQuery.error} />
        </div>
        <div className="col my-auto">
          <h5>Current</h5>
          <FallbackImage src={blackboardPhoto} alt="Current Id" error={blackboardPhotoQuery.error} />
        </div>
        <div className="col my-auto text-start">
          <div className="d-grid gap-2 col-8 mx-auto mt-3">
            <button className="btn btn-outline-primary"
              onClick={cancelHandler}
              disabled={markCompleteMutation.isLoading}>
              {markCompleteMutation.isLoading ? "Processing..." : "Mark Completed"}
            </button>
            <a
              className="btn btn-outline-secondary my-4"
              download={`${photoInfo.badgerid}.jpg`}
              href={profilePhoto}
            >
              Download Photo
            </a>
          </div>
        </div>
      </div>
      <hr className="my-4" />
    </>
  );
};
