import { TabbedMenu } from "../../components/ui/TabbedMenu";
import { Approve } from "./approve/Approve";
import { Completed } from "./Completed";
import { Denied } from "./Denied";
import { Overview } from "./overview/Overview";
import { Print } from "./print/Print";
import { PrintMed } from "./print/PrintMed";

export const Admin = () => {
  const tabs = [
    {
      key: "overview",
      name: "Overview",
      component: <Overview />,
    },
    {
      key: "approve",
      name: "Approve",
      component: <Approve />,
    },
    {
      key: "ephraim",
      name: "Print Ephraim",
      component: <Print isEphraim={true} />,
    },
    {
      key: "richfield",
      name: "Print Richfield",
      component: <Print isEphraim={false} />,
    },
    {
      key: "printmed",
      name: "Print Med",
      component: <PrintMed />,
    },
    {
      key: "denied",
      name: "Denied",
      component: <Denied />,
    },
    {
      key: "completed",
      name: "Completed",
      component: <Completed />,
    },
  ];
  return (
    <div>
      <h1 className="text-center mt-4">Id Photo Admin</h1>
      <TabbedMenu tabs={tabs} />
    </div>
  );
};
