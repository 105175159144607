import { Spinner } from "../../../components/ui/Spinner";
import {
  useGetApprovedMedQuery,
  useGetApprovedQuery,
} from "../adminHooks";
import { useGetAwaitingApprovalQuery } from "../approve/approvalHooks";
import { OverviewItem } from "./OverviewItem";

export const Overview = () => {
  const inProgressQuery = useGetAwaitingApprovalQuery();
  const approvedQuery = useGetApprovedQuery();
  const approvedMedQuery = useGetApprovedMedQuery();
  if (
    inProgressQuery.isFetching ||
    approvedQuery.isFetching ||
    approvedMedQuery.isFetching
  )
    return <Spinner />;

  if (
    inProgressQuery.data?.length === 0 &&
    approvedQuery.data?.length === 0 &&
    approvedMedQuery.data?.length === 0
  )
    return <h3 className="text-center">No Pending Requests</h3>;

  return (
    <div className="text-center">
      {inProgressQuery.data?.map((p) => (
        <OverviewItem photoInfo={p} key={p.badgerid} />
      ))}
      {approvedQuery.data?.map((p) => (
        <OverviewItem photoInfo={p} key={p.badgerid} />
      ))}
      {approvedMedQuery.data?.map((p) => (
        <OverviewItem photoInfo={p} key={p.badgerid} />
      ))}
    </div>
  );
};
