import { useNavigate } from "react-router-dom";
import { useGetStatusQuery } from "../../hooks/photoHooks";


export const Home = () => {
  const navigate = useNavigate();
  const statusQuery = useGetStatusQuery();

  const uploadPhoto = () => {
    navigate({
      pathname: "/upload",
    });
  };

  

  return (
    <div className="text-center mt-2">
      <h1>Snow College Id Photo Upload</h1>
      <h2>Photo Change Status: {statusQuery.data}</h2>
      <img
        src="/api/student/currentprofilephoto"
        alt="Student"
        style={{ height: "20em" }}
      />
      <div className="row mt-3">
        <div className="col-4"></div>
        <div className="col-4 text-center">
          <button className="btn btn-bold" onClick={uploadPhoto}>
            Upload New Photo
          </button>
        </div>
      </div>
    </div>
  );
}