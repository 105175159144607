import { Toaster } from "react-hot-toast"
import { Route, Routes } from "react-router-dom"
import ErrorBoundary from "./components/ErrorBoundary"
import { NavBar } from "./components/NavBar"
import { Admin } from "./pages/admin/Admin"
import { Home } from "./pages/home/Home"
import { Upload } from "./pages/upload/Upload"


export const AppRoutes = () => {
  return (
    <>
      <NavBar />
      <Toaster />
      <div className="container">
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="/upload" element={
            <ErrorBoundary>
              <Upload />
            </ErrorBoundary>
          } />
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </div>
    </>

  )
}