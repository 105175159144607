import { AxiosRequestHeaders } from "axios";
import { useAuth } from "react-oidc-context";
import { User } from "../models/User";

export const useUser = () => {
  const auth = useAuth();
  if (!auth.user) {
    // auth.signinRedirect();
    throw new Error("logging in")
  }
  return auth.user.profile as User
}

export const useAccessTokenHeader = (): AxiosRequestHeaders => {
  const auth = useAuth();
  if (!auth.user) {
    // auth.signinRedirect();
    throw new Error("logging in")
  }
  const token = auth.user.access_token;
  return { Authorization: `Bearer ${token}` }
}
