import { FC, useState } from "react";
import { Spinner } from "../../../components/ui/Spinner";
import { useMarkDeniedMutation } from "./approvalHooks";

export const DenyReasons: FC<{
  badgerid: string;
  chargeFee: boolean;
}> = ({ badgerid, chargeFee }) => {
  const markDeniedMutation = useMarkDeniedMutation(badgerid);
  const [otherReason, setOtherReason] = useState("")
  const denyReasons = [
    "Photo must be recent and in color (a selfie is preferred)",
    "Use a plain, light-colored background (preferably white or tan)",
    "Image must be clear and devoid of filters",
    "No hats, hand signs, sunglasses, or masks",
    "Face the camera directly with full face in view",
    "Did not upload an appropriate government identification in order to verify identity",
    "Other"
  ];
  const [reasons, setReasons] = useState<string[]>([]);

  const denyHandler = () => {
    const updatedReasons = reasons.map((reason) =>
      reason === "Other" ? otherReason : reason
    );
    markDeniedMutation.mutate({ reasons: updatedReasons, chargeFee });
  };

  const reasonIsSelected = (r: string) =>
    !!reasons.find((reason) => reason === r);

  const addReasonHandler = (r: string) => {
    reasonIsSelected(r)
      ? setReasons((lastReason) => lastReason.filter((reason) => reason !== r))
      : setReasons((lastReason) => [...lastReason, r]);
  };

  return (
    <>
      <div>
        {denyReasons.map((r) => (
          <div className="form-check" key={r}>
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="checkbox"
                checked={reasonIsSelected(r)}
                onChange={() => addReasonHandler(r)}
              />
              {r}
            </label>
            {r === "Other" && reasonIsSelected(r) && (
              <div className="ml-3">
                <div className="form-group mb-0">
                  <input
                    type="text"
                    className="form-control"
                    value={otherReason}
                    onChange={(e) => setOtherReason(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      <button
        className="btn btn-bold"
        onClick={denyHandler}
        disabled={markDeniedMutation.isLoading}
      >
        Submit Changes
      </button>
      {markDeniedMutation.isLoading && <Spinner />}
    </>
  );
};
