import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { FC, useEffect, useRef } from "react";
import { CustomModal, CustomModalControls, ModalButton } from "./ui/CustomModal";

export const CropModal: FC<{
  modalControl: CustomModalControls,
  ModalButton: ModalButton,
  setCroppedPhotoUrl: (u: string) => void,
  uploadedPhotoUrl: string,
  setUploadedPhotoUrl: (u: string) => void,
}> = ({
  modalControl,
  ModalButton,
  setCroppedPhotoUrl,
  uploadedPhotoUrl
}) => {
    const imageRef = useRef<HTMLImageElement>(null);
    var cropper: Cropper;
    useEffect(() => {
      if (imageRef.current && uploadedPhotoUrl !== "") {
        cropper = new Cropper(imageRef.current, {
          aspectRatio: 2 / 3,
          background: false,
          autoCropArea: 1,
          viewMode: 1
        })
        cropper.crop()
      }
    }, [imageRef, uploadedPhotoUrl])

    const cropHandler = () => {
      const croppedCanvas = cropper.getCroppedCanvas({
        width: 540,
        height: ((540 * 4) / 3)
      })
      setCroppedPhotoUrl(croppedCanvas.toDataURL("image/jpeg"))
      modalControl.hide();
    }

    return (
      <CustomModal controls={modalControl} ModalButton={ModalButton}>
        <div className="modal-body text-center" key={Date.now()}>
          <div>
            <img src={uploadedPhotoUrl} alt="Uploaded" ref={imageRef} height={500} />
          </div>
          <div className="mt-2">
            <button className="btn btn-bold" onClick={cropHandler}>Crop and Upload Photo</button>
          </div>
        </div>
      </CustomModal>
    )
  }