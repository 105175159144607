import { useMutation, useQuery } from "react-query";
import { useAccessTokenHeader } from "../../../hooks/userHooks";
import { adminService } from "../adminService";
import { getQueryClient } from "../../../services/queryClient";
import { adminKeys } from "../adminHooks";

const queryClient = getQueryClient();

export const adminApprovalKeys = {
  awaitingApproval: ["awaitingApproval"] as const,
};

export const useGetAwaitingApprovalQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery(adminApprovalKeys.awaitingApproval, async () => {
    return await adminService.getInProgress(authHeader);
  }, { staleTime: 300000 });
};

export const useMarkApprovedMutation = (badgerid: string) => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async (chargeFee: boolean) => {
      return await adminService.markApproved(badgerid, chargeFee, authHeader);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(adminApprovalKeys.awaitingApproval);
        queryClient.invalidateQueries(adminKeys.approvedKey);
        queryClient.invalidateQueries(adminKeys.approvedMedKey);
      },
    }
  );
};

export const useMarkDeniedMutation = (badgerid: string) => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      reasons,
      chargeFee,
    }: {
      reasons: string[];
      chargeFee: boolean;
    }) => {
      return await adminService.markDenied(
        badgerid,
        reasons,
        chargeFee,
        authHeader
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(adminApprovalKeys.awaitingApproval);
        queryClient.invalidateQueries(adminKeys.approvedKey);
        queryClient.invalidateQueries(adminKeys.approvedMedKey);
        queryClient.invalidateQueries(adminKeys.deniedKey)
      },
    }
  );
};
