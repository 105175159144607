import { FC } from "react";

export const CampusSelect: FC<{
  setCampus: (c: string) => void
}> = ({ setCampus }) => {
  return (
    <div className="my-1">
      <div>Select a campus:</div>
      <div className="row">
        <div className="col"></div>
        <div className="col-auto">
          <div className="form-check" >
            <label className="form-check-label">
              <input type="radio" className="form-check-input" name="campus" onClick={() => setCampus("Ephraim")} />
              Ephraim
            </label>
          </div>
          <div className="form-check" >
            <label className="form-check-label">
              <input type="radio" className="form-check-input" name="campus" onClick={() => setCampus("Richfield")} />
              Richfield
            </label>
          </div>
        </div>
        <div className="col"></div>
      </div>
    </div>
  )
}