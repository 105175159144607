import { FC, useState } from "react";


export const FallbackImage: FC<{
  src: string,
  alt: string,
  height?: number,
  error?: any
}> = ({ src, alt, height = 200, error }) => {
  const [imageSrc, setImageSrc] = useState(src)
  const [imgIsLoading, setImgIsLoading] = useState(true);
  const statusCode = error ? error.message.split(" ")[5] : "200"
  const errorHandler = () => {
    if (statusCode === "500") {
      setImageSrc("invalid_format.png")
    }
    else if (statusCode === "404") {
      setImageSrc("white_badger_1280x1024.jpg")
    }
  }

  return (
    <div>
      <img src={imageSrc}
        alt={alt}
        height={height}
        hidden={imgIsLoading}
        onLoad={() => setImgIsLoading(false)}
        className="border"
        onError={errorHandler} />
    </div>
  )
}