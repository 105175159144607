import React, { Component, ErrorInfo, ReactNode } from "react";
import { createErrorToast } from "../services/queryClient";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    createErrorToast(error + "");
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <>
          <h1>Sorry... there was an error</h1>
          <div>Please submit a ticket with a screenshot to the <a href="https://snow.teamdynamix.com/TDClient/283/Portal/Requests/TicketRequests/NewForm?ID=7uGyLLUtGsk_&RequestorType=Service">IT Help Desk</a>
          </div>
        </>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
