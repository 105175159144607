import { FC, useEffect, useRef } from "react";
import { Spinner } from "../../../components/ui/Spinner";
import { useCheckForPrintedPhotosMutation, useGetApprovedQuery } from "../adminHooks";
import { PrintItem } from "./PrintItem";
import { Popover } from "bootstrap";

export const Print: FC<{
  isEphraim: boolean
}> = ({ isEphraim }) => {
  const popoverRef = useRef<HTMLButtonElement>(null);
  const approvedQuery = useGetApprovedQuery();
  const checkForPrintedPhotosMutation = useCheckForPrintedPhotosMutation();

  useEffect(() => {
    if (popoverRef.current) {
      new Popover(popoverRef.current, {
        content: "Automatically complete any approved photos that have been printed",
        trigger: "hover",
        placement: "top",
      });
    }
  });

  if (approvedQuery.isFetching) return <Spinner />;
  if (approvedQuery.isError) return <h3 className="text-center">Error getting print requests</h3>
  if (!approvedQuery.data) return <h3 className="text-center">No data found</h3>

  const ephraimPhotos = approvedQuery.data.filter(p => isEphraim && p.campus === "Ephraim")
  const richfieldPhotos = approvedQuery.data.filter(p => !isEphraim && p.campus === "Richfield")

  if ((isEphraim && ephraimPhotos.length === 0) ||
    (!isEphraim && richfieldPhotos.length === 0))
    return (
      <div className="text-center">
        <h3>No Requests to Print</h3>
        <button
          className="btn btn-bold"
          onClick={() => approvedQuery.refetch()}
        >
          Refresh
        </button>
      </div>
    );

  return (
    <div className="text-center">
      <div className="row">
        <div className="col offset-3">
          <button className="btn btn-bold" onClick={() => approvedQuery.refetch()}>
            Refresh
          </button>
        </div>
        <div className="col-3 text-end">
          <button className="btn btn-secondary"
            onClick={() => checkForPrintedPhotosMutation.mutate()}
            disabled={checkForPrintedPhotosMutation.isLoading}
            ref={popoverRef}
            data-bs-toggle="popover">
            {checkForPrintedPhotosMutation.isLoading ? "Checking..." : "Check For Printed Photos"}
          </button>
        </div>
      </div>
      <div className="text-center mt-2">*Note: If New and Current photos do not match please contact IT</div>
      <hr />
      {isEphraim && ephraimPhotos.map((p) => (
        <PrintItem photoInfo={p} key={p.badgerid} />
      ))}
      {!isEphraim && richfieldPhotos.map((p) => (
        <PrintItem photoInfo={p} key={p.badgerid} />
      ))}
    </div>
  );
};
