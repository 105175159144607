import { Spinner } from "../../components/ui/Spinner";
import { useGetCompletedQuery } from "./adminHooks";

export const Completed = () => {
  const completeQuery = useGetCompletedQuery();

  if (completeQuery.isFetching) return <Spinner />;
  if (completeQuery.isError) return <h3 className="text-center">Error getting completed requests</h3>
  if (!completeQuery.data) return <h3 className="text-center">No data found</h3>
  if (completeQuery.data.length === 0)
    return <h3 className="text-center">No Completed Requests</h3>;

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Name</th>
          <th>Badger Id</th>
          <th>Campus</th>
          <th>Approved By</th>
          <th>Approval Date</th>
          <th>Completed By</th>
          <th>Completed Date</th>
        </tr>
      </thead>
      <tbody>
        {completeQuery.data.map((p) => (
          <tr>
            <td>{p.displayName}</td>
            <td>{p.badgerid}</td>
            <td>{p.campus}</td>
            <td>{p.approvedBy}</td>
            <td>
              {p.approvedTimestamp ?
                new Date(p.approvedTimestamp).toDateString() :
                ""}
            </td>
            <td>{p.completedBy}</td>
            <td>
              {p.completedTimestamp ?
                new Date(p.completedTimestamp).toDateString() :
                ""}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
