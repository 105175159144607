import { FC } from "react";
import { useGetPhotoQuery } from "../adminHooks";
import { IdPhotoInfo } from "../../../models/IdPhotoInfo";
import { Spinner } from "../../../components/ui/Spinner";
import { FallbackImage } from "../../../components/ui/FallbackImage";

export const OverviewItem: FC<{
  photoInfo: IdPhotoInfo;
}> = ({ photoInfo }) => {
  const { data, error, isFetching } = useGetPhotoQuery(photoInfo.badgerid);
  const profilePhoto = data

  return (
    <div className="row">
      <div className="col-4 my-auto offset-3">
        <div className="fs-3">{photoInfo.displayName}</div>
        <div>{photoInfo.status}</div>
      </div>
      <div className="col text-start my-auto">
        {isFetching ? (
          <Spinner />
        ) : (
          <FallbackImage src={"data:image/jpg;base64," + profilePhoto} alt="New Id" error={error} />
        )}
      </div>
      <hr className="my-4" />
    </div>
  );
};
